<template>
  <b-row>
    <b-col cols="12" xl="4" class="nopadding">
      <b-card>
        <div slot="header">
          <strong># {{$t('common.new_service')}}</strong>
        </div>
        <div class="text-center">
          <img v-if="product.logo" :src="computed_logo(product.logo)"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <img v-else src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <h6>{{$t('common.choose_another_image')}}</h6>
          <input type="file" class="text-center center-block file-upload"
          @change="getImage">
        </div>
        <br>
        <div class="row">
          <div class="col bg-secondary">
            <b-form-group>
              <label for="txt_name">{{$t('common.service')}}</label>
              <div v-if="$v.product.name.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.product.name.required">{{$t('common.name_required')}}</div>
                  <div class="text-danger" v-else-if="!$v.product.name.isUnique">{{$t('common.name_existing')}}</div>
              </div>
              <b-form-input type="text" id="txt_name" :placeholder="$t('common.placeholder_name')"
              v-model="product.name" maxlength="50" ref="txt_name" class="input-sm" size="sm"
              v-on:input="$v.product.name.$touch"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_code">{{$t('common.code')}}</label>
              <div v-if="$v.product.code.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.product.code.required">{{$t('common.code_required')}}</div>
                <div class="text-danger" v-else-if="!$v.product.code.isUnique">{{$t('common.code_existing')}}</div>
              </div>
              <b-form-input type="text" id="txt_code" :placeholder="$t('common.placeholder_code')"
              v-model="product.code" maxlength="25" :disabled="product.id!=null" size="sm"
              v-on:input="$v.product.code.$touch"></b-form-input>
            </b-form-group>
            <div class="form-group">
              <fieldset class="scheduler-border col-md-12">
                <legend class="scheduler-border">{{$t('common.unit')}} & {{$t('common.price')}}</legend>
                <div class="row d-flex justify-content-between align-items-center" v-for="(itm, k) in $v.product.product_units.$each.$iter"
                :key="k">
                  <div class="form-group col-md-5 form-fixer">
                    <label :for="'txt_name'+k">{{$t('common.unit')}}</label>
                    <div v-if="$v.product.product_units.$error" class="pull-right">
                      <div class="text-danger" v-if="!itm.unit_id.required">{{$t('common.unit_required')}}</div>
                      <div class="text-danger" v-else-if="!itm.unit_id.isUnique">{{$t('common.unit_existing')}}</div>
                    </div>
                    <b-form-select id="ddl_dvt" v-model="itm.unit_id.$model"
                    v-on:input="itm.unit_id.$touch" size="sm">
                        <option value="null" disabled>{{$t('common.select_unit')}}</option>
                        <option v-for="d in ls_unit_ddl" :value="d.id"
                            v-bind:key="d.id">{{JSON.parse(d.name)[$i18n.locale]}}</option>
                    </b-form-select>
                  </div>

                  <div class="form-group col-md-5 form-fixer">
                    <label v-if="business=='pawn'" :for="'txt_sell_price_'+k">{{$t('common.price')}}</label>
                    <label v-else :for="'txt_sell_price_'+k">{{$t('common.ex_price')}}</label>
                    <div v-if="$v.product.product_units.$error" class="pull-right">
                      <div class="text-danger" v-if="!itm.sell_price.required">{{$t('common.price_required')}}</div>
                    </div>
                    <money v-model.trim="itm.sell_price.$model" v-bind="money" class="form-control"
                    :name="'txt_sell_price_'+k" :id="'txt_sell_price_'+k" :disabled="itm.status.$model<0"
                    maxLength="15"></money>
                  </div>

                  <div class="col-md-2 pull-right text-white" v-if="itm.status.$model>-1">
                    <div v-if="product.id==null">
                      <a class="btn btn-danger mr-1 btn-sm" v-if="product.product_units.length>1"
                        @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                      <a class="btn btn-success btn-sm" @click="addNewRow"><i class="fa fa-plus" aria-hidden="true"></i></a>
                    </div>
                    <div v-else>
                      <a class="btn btn-danger mr-1 btn-sm"
                      @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                      <a class="btn btn-success btn-sm" @click="addNewRow"><i class="fa fa-plus" aria-hidden="true"></i></a>
                    </div>
                  </div>
                  <div class="col-md-2 pull-right text-white" v-else>
                    <div>
                      <a class="btn btn-success mr-1 btn-sm" v-if="product.product_units.length>1"
                        @click="itm.status.$model=0"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="row">
              <div class="col-md-3">
                <b-form-group v-if="business=='vlxd'||business=='pawn'||is_super">
                  <label for="txt_long">{{$t('common.long')}}</label>
                  <b-form-input type="text" id="txt_long" size="sm"
                  v-model="product.long" maxlength="10"
                  :placeholder="$t('common.placeholder_long')"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group v-if="business=='vlxd'||business=='pawn'||is_super">
                  <label for="txt_wide">{{$t('common.wide')}}</label>
                  <b-form-input type="text" id="txt_wide"
                  v-model="product.wide" maxlength="10" size="sm"
                  :placeholder="$t('common.placeholder_wide')"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group v-if="business=='vlxd'||business=='pawn'||is_super">
                  <label for="txt_height">{{$t('common.height')}}</label>
                  <b-form-input type="text" id="txt_height" size="sm"
                  v-model="product.height" maxlength="10"
                  :placeholder="$t('common.placeholder_height')"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group v-if="business=='vlxd'||business=='pawn'||is_super">
                  <label for="txt_weight">{{$t('common.weight')}}</label>
                  <b-form-input type="text" id="txt_weight" size="sm"
                  v-model="product.weight" maxlength="200"
                  :placeholder="$t('common.placeholder_weight')"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b-form-group v-if="business=='vlxd'||business=='pawn'||is_super">
                  <label for="txt_sign">{{$t('common.sign')}}</label>
                  <b-form-input type="text" id="txt_sign" size="sm"
                  v-model="product.sign" maxlength="200"
                  :placeholder="$t('common.placeholder_sign')"></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group v-if="business=='vlxd'||business=='pawn'||is_super">
                  <label for="txt_origin">{{$t('common.origin')}}</label>
                  <b-form-input type="text" id="txt_origin" size="sm"
                  v-model="product.origin" maxlength="200"
                  :placeholder="$t('common.origin')"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <b-form-group>
              <label for="txt_des">{{$t('common.description')}}</label>
              <b-form-textarea type="text" id="txt_des"
              v-model="product.des" maxlength="200"
              :placeholder="$t('common.placeholder_description')"></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <div class="row pull-right mt-2">
          <b-button variant="outline-success" class="mr-1" size="sm"
          @click.prevent="clear()"><i class="fa fa-refresh"></i> {{$t('common.button.refresh')}}</b-button>
          <b-button variant="outline-primary" size="sm" @click.prevent="saveSp()"><span class="icon is-small">
            <i class="fa fa-check"></i> {{$t('common.button.save')}}</span></b-button>
        </div>
      </b-card>
    </b-col>
    <b-col cols="12" xl="8" class="nopadding">
      <transition name="slide">
      <b-card>
        <div slot="header"><strong><i class="fa fa-list" aria-hidden="true"></i> {{$t('common.service_list')}}</strong></div>
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter" maxlenght="50" @keyup.enter="apply_filter">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive :tbody-tr-class="rowDeleted"
        :items="filteredAndSortedData" :fields="fields" :current-page="page"
        :per-page="0" @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
          <template slot="name" slot-scope="data">
            <img v-if="data.item.logo" :src="computed_logo(data.item.logo)"
            class="avatar img-circle img-thumbnail" alt="avatar">
            <img v-else src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
            class="avatar img-circle img-thumbnail" alt="avatar">
            <br/>
            <label>{{data.item.name}}</label>
          </template>
          <template slot="branch" slot-scope="data">
            <label v-if="data.item.branch">{{data.item.branch.name}}</label>
            <label v-else></label>
          </template>
          <template slot="company" slot-scope="data">
            <label v-if="data.item.company">{{data.item.company.name}}</label>
            <label v-else></label>
          </template>
          <template slot="updated_at" slot-scope="data">
            <label v-if="data.item.updated_at">{{VnDateTimeFormat(data.item.updated_at)}}</label>
            <label v-else></label>
          </template>
          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
          <template slot="phone" slot-scope="data">
            <b-badge>{{data.item.phone}}</b-badge>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getObjectStatus(data.item.status)}}</b-badge>
          </template>
          <template slot="action" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="outline-danger"
              v-if="data.item.status>-1"
                @click="confirmDelete(data.item)">
                <i class="fa fa-trash"></i> {{$t('common.button.delete')}}
              </b-button>
              <b-button variant="outline-success" v-else
                @click="recoverObj(data.item)">
                <i class="fa fa-recycle"></i> {{$t('common.button.recover')}}
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <template slot="footer">
            <b-row class="pull-right">
              <b-col>
                <nav>
                  <pager :frame="8"
                        :pageCount="page_count"
                        :page="page"
                        @change="change_page">
                  </pager>
                </nav>
              </b-col>
            </b-row>
          </template>
      </b-card>
      </transition>
    </b-col>
  </b-row>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
  fieldset.scheduler-border {
    border: 1px groove #e4e7ea !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
  }

  legend.scheduler-border {
      font-size: 1.2em !important;
      font-weight: bold !important;
      text-align: left !important;
      width:auto;
      padding:0 10px;
      border-bottom:none;
  }
</style>
<script>
import { validationMixin } from 'vuelidate'
import { required,requiredIf,maxLength,numeric }
from 'vuelidate/lib/validators'
import moment from 'moment'
import Pager from '../Pager'
import { mapGetters, mapActions } from 'vuex'
import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
import { Role } from '@/helpers/role.js'
import { Business } from '../../helpers/business'

export default {
  name: 'product',
  mixins: [validationMixin],
  components: {Pager},
  props: {
    hover: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      processing:false,
      options_loai_product: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '$ ',
        precision: 0,
        masked: false
      },
      money_discount: {
        decimal: ',',
        thousands: '.',
        prefix: '% ',
        precision: 0,
        masked: false
      },
      product:{
        id:null,
        logo:null,
        cocon:false,
        name:null,
        business:null,
        company_id:null,
        unit_id:null,
        branch_id:null,
        code:moment().valueOf().toString(),
        origin:null,
        user_create_id:null,
        user_update_id:null,
        long:null,
        wide:null,
        height:null,
        weight:null,
        square:null,
        cubic:null,
        buy_price:0,
        sell_price:0,
        sign:null,
        des:null,
        type:0,
        discount:0,
        product_units:[]
      }
    }
  },
  validations: {
    product: {
      code:{
        required,
        maxLength:maxLength(25),
        async isUnique(value) {
          debugger
          if (value==null) return true
          if(this.product && this.product.id){
            return true
          }

          const res = await this.checkCodeUnique({code:value,company_id:this.user_login.company_id})
          return Boolean(res.data)
        }
      },
      name: {
        required,
        maxLength:maxLength(50),
        async isUnique(value) {
          debugger
          if (value==null) return true

          const res = await this.checkNameUnique({product_id:this.product.id,name:value,company_id:this.user_login.company_id})
          return Boolean(res.data)
        }
      },
      product_units: {
        $each: {
          unit_id:{
            required: requiredIf(function () {
              return this.product.product_units.length>0
            }),
            async isUnique(value,itm) {
              debugger
              if(this.product.product_units.length<=0) return Boolean(true)
              let arr=this.product.product_units.filter(x => x.unit_id == itm.unit_id)
              return Boolean(arr.length<=1)
            }
          },
          sell_price:{
            required: requiredIf(function () {
              return this.product.product_units.length>0
            }),
            numeric
          },
          status:{}
        }
      }
    }
  },
  computed: {
    ...mapGetters('st_product', ['page_count','page','filter','per_page','type']),
    fields(){
      let fields=[]
      let user=this.$store.state.st_authentication.user
      if(user.role==Role.Super_Admin){
        fields.push({key: 'company',label:this.$t('common.company'),sortable: true, variant:'info'})
        fields.push({key: 'branch',label:this.$t('common.branch'),sortable: true})
        fields.push({key: 'business',label:this.$t('common.business'),sortable: true})
      }

      fields.push({key: 'name',label:this.$t('common.service'),sortable: true, variant:'primary'})
      fields.push({key: 'code',label:this.$t('common.code'),sortable: true})
      if(user.company.business==Business.vlxd || user.company.business==Business.pharmacy){
        fields.push({key: 'sell_price',label:this.$t('common.price'),sortable: true})
      }

      fields.push({key: 'created_at',label:this.$t('common.created_at'),sortable: true})
      fields.push({key: 'status',label:this.$t('common.status'),sortable: true})

      if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
        fields.push({key: 'action',label:'',sortable: false})
      }

      return fields
    },
    is_super() {
      let user=this.$store.state.st_authentication.user
      return user&&user.role.value==Role.Super_Admin
    },
    ls_unit_ddl:{
      get: function(){
        return this.$store.state.st_unit.ls_unit_ddl
      }
    },
    user_login() {
      return this.$store.state.st_authentication.user
    },
    business() {
      let user= this.$store.state.st_authentication.user
      return user&& user.company.business
    },
    ls_company_ddl:{
      get: function(){
        return this.$store.state.st_product.ls_company_ddl
      }
    },
    filter:{
      get: function(){
        return this.$store.state.st_product.filter
      },
      set: function(text){
        this.$store.commit('st_product/set_filter',text)
      }
    },

    type:{
      get: function(){
        return this.$store.state.st_product.type
      },
      set: function(val){
        this.$store.commit('st_product/set_type',val)
      }
    },
    filteredAndSortedData:{
      get: function(){
        let vm=this
        let result = vm.$store.state.st_product.ls_product
        if (vm.filter) {
            result = result.filter(item =>vm.searchLike(item))
        }

        return result
      }
    }
  },
  methods: {
    ...mapActions('st_product', ['get_ls_product','change_page', 'apply_filter']),
    ...mapActions('st_unit', ['get_ls_unit_ddl']),
    ...mapActions('st_company', ['get_ls_company_ddl']),
    clear(){
      this.product={id:null,company_id:null,logo:null,long:null,wide:null,height:null,weight:null,origin:null,name:null,
      user_create_id:this.user_login.id,user_update_id:null,type:1,discount:0,product_units:[]
      ,buy_price:0,sell_price:0,des:null,cocon:false,business_id:null,square:null,
      cubic:null,sign:null,code:moment().valueOf().toString()}
      this.$refs.txt_name.$el.focus()
      this.addNewRow()
      this.$v.$reset()
    },
    rowDeleted(item, type){
      debugger
      if (!item || type !== 'row') return
      debugger
      if (item.status === -1) return 'table-danger'
    },

    addNewRow() {
      this.product.product_units.push({
        id:null,
        company_id:this.user_login.company_id,
        branch_id:this.user_login.branch_id,
        business:this.user_login.company.business,
        user_create_id:this.user_login.id,
        product_id:this.product.id,
        buy_price:0,
        sell_price:0,
        status:1,
        unit_id:null
      })
    },

    async checkNameUnique(param){
      return await this.$store.dispatch(`st_product/name_unique`,param)
    },

    async checkCodeUnique(param){
      return await this.$store.dispatch(`st_product/code_unique`,param)
    },

    async recoverObj(obj){
      let app=this
      if(obj.status>=0) return
      let co=await this.$store.dispatch('st_product/recover_obj',obj.id)
      if(co && co.data.ok){
        this.get_ls_product(1)
        app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    },
    confirmDelete(obj) {
      this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then(value => {
        if(value!=true) return
        this.deleteObj(obj)
      })
      .catch(err => {
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      })
    },
    async deleteObj(obj) {
      let app=this
      if(obj.status<0) return
      if(obj.status==1){
        app.$toastr.info(app.$t("common.item_using_message",{obj:app.$t("common.product")}),app.$t("common.message"))
        return
      }
      let co=await this.$store.dispatch('st_product/delete_obj',obj.id)
      if(co && co.data.ok){
        this.get_ls_product(1)
        app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    },
    _validations () {
      return new Promise(resolve => {
        if (this.$v.$error || !this.$v.$pending) {
            return resolve()
        }
        let poll = setInterval(() => {
        if (!this.$v.$pending) {
            clearInterval(poll)
            resolve()
        }
        }, 200)
      })
    },
    async isValid () {
      debugger
      this.$v.$reset()
      this.$v.$touch()
      await this._validations()
      return Promise.resolve(!this.$v.$error)
    },
    async saveSp() {
      const isValid = await this.isValid()
      debugger
      if (!isValid) {
          return
      }

      var app = this
      if(app.processing ===true) return

      app.processing = true
      debugger
      if(app.product.id){
        if(!app.is_super){
          app.product.user_update_id=app.user_login.id
        }

        app.$store.dispatch('st_product/update',app.product)
        .then(function (resp) {
          app.get_ls_product(1)
          app.clear()
          app.processing = false
          app.$toastr.success(app.$t('common.updated_success'),app.$t("common.message"))
        })
        .catch(function (resp) {
          app.processing = false
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
      }else{
        if(!app.is_super){
          app.product.user_create_id=app.user_login.id
          app.product.company_id=app.user_login.company_id
          app.product.branch_id=app.user_login.branch_id
          app.product.business=app.user_login.company.business
        }

        app.$store.dispatch('st_product/create',app.product)
        .then(function (resp) {
          app.get_ls_product(1)
          app.clear()
          app.processing = false
          app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
        })
        .catch(function (resp) {
          app.processing = false
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
      }
    },
    getRowCount (items) {
      return items.length
    },
    rowClicked (item) {
      debugger
      this.product=item
    },
    searchLike:function(item){
      return item.name.includes(this.filter)
      ||item.buy_price.toString().includes(this.filter)
      ||item.sell_price.toString().includes(this.filter)
      ||item.unit&&item.unit.name.includes(this.filter)
      ||item.branch&&item.branch.name.includes(this.filter)
      ||item.business&&item.business.includes(this.filter)
      ||item.company&&item.company.name.includes(this.filter)
      ||item.created_at.includes(this.filter)
      ||item.updated_at&&item.updated_at.includes(this.filter)
      ||item.address&&item.address.includes(this.filter)
      ||item.long&&item.long.includes(this.filter)
      ||item.height&&item.height.includes(this.filter)
      ||item.wide&&item.wide.includes(this.filter)
      ||item.weight&&item.weight.includes(this.filter)
      ||item.square&&item.square.includes(this.filter)
      ||item.cubic&&item.cubic.includes(this.filter)
      ||item.code.includes(this.filter)
      ||item.origin&&item.origin.includes(this.filter)
      ||item.des&&item.des.includes(this.filter)
    },
    getImage:function(e){
      let app=this
      let image=e.target.files[0]
      let reader=new FileReader()
      reader.readAsDataURL(image)
      reader.onload=e=>{
        app.product.logo=e.target.result
      }
    },
    removeRow(k){
      if(k==null || parseInt(k)<0) return

      debugger
      let itm=this.product.product_units[k]
      if(itm && itm.id){
        // confirm delete
        this.confirmRemoveDetail(k)
      }else{
        debugger
        this.product.product_units.splice(k, 1)
      }
    },
    confirmRemoveDetail(k) {
      this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then(value => {
        if(value!=true) return
        this.product.product_units[k].status=-1
      })
      .catch(err => {
        this.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      })
    }
  },
  mounted () {
    let vm=this
    vm.type=0
    debugger
    vm.$refs.txt_name.$el.focus()
    vm.options_user_type=vm.option_business
    vm.addNewRow()
    vm.get_ls_product(1)
    vm.get_ls_company_ddl(vm.user_login.company_id)
    vm.get_ls_unit_ddl({company_id:vm.user_login.company_id,business:vm.user_login.company.business,lang:vm.$i18n.locale})
  }
}
</script>
